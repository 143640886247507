import React from "react"
import { Link, graphql } from "gatsby"
import Seo from "../components/seo"
import { Layout, CategoryMenu } from "../components/index"
import { SharingButton } from "../components/Single/index"
import { StaticImage } from "gatsby-plugin-image"//ローカルから画像を持ってくる
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import { LowerHeader, LowerHeaderBg } from "../components/LowerPages/index"
import "../styles/LowerPage.scss";
import '../styles/Single.scss'

const Single = ({ data, pageContext }) => {
  const breakpoints = useBreakpoint();

  const post = data.allWpPost.edges[0].node
  // console.log(pageContext)
  const title1 = [
    { "id": 0, "text": "n" },
    { "id": 1, "text": "e" },
    { "id": 2, "text": "w" },
    { "id": 3, "text": "s" }
  ];
  return (
    <Layout className={post.categories.nodes[0].name} pageName={post.categories.nodes[0].name} title={`お知らせ`}>
      <Seo
        title={post.title}
      />
      <div className={`LowerHeadImg`}>
        {
          breakpoints.sm ?
            <StaticImage
              layout="fullWidth"
              src="../images/news/news_header-sp.png"
              alt="news_header"
            />
            :
            <StaticImage
              layout="fullWidth"
              src="../images/news/news_header.png"
              alt="news_header"
            />

        }
      </div>
      <div className={`LowerWrapper`}>
        <LowerHeader data={title1} title={`お知らせ`}>
          <LowerHeaderBg />
        </LowerHeader>
        <div className={`LowerContent`}>
          <div className={`RadiusCircle`} ></div>
          <div className={`LowerContent__inner`}>
            <CategoryMenu />
            <article className={`SingleArticle`}>
              <div className={`postCat ` + post.categories.nodes[0].slug}>{post.categories.nodes[0].name}</div>
              <h1 className={`postTitle`}>{post.title}</h1>
              <div className={`postInfo`}>
                <div className={`postDate`}>{post.date}</div>
                <SharingButton title={post.title} url={post.link} />
              </div>
              <div className={`postCont`} dangerouslySetInnerHTML={{ __html: post.content }} />
              <div className={`postFooter`}>
                <div className={`prevPager`}>
                  {pageContext.next !== null ? //nextが前の記事を参照しているので注意
                    <a href={pageContext.next.link}>
                      前の記事
                    </a>
                    :
                    null
                  }
                </div>
                <div className={`listPager`}>
                  <a href={`/news/`}>お知らせ一覧に戻る</a>
                </div>
                <div className={`nextPager`}>
                  {pageContext.prev !== null ? //prevが次の記事を参照しているので注意
                    <a href={pageContext.prev.link}>
                      次の記事
                    </a>
                    :
                    null
                  }
                </div>
              </div>
            </article>
            <CategoryMenu />
          </div>
        </div>
      </div>
    </Layout>
  )
}
export default Single;

export const query = graphql`
 query($slug: String!) {
   site {
     siteMetadata {
       title
       siteUrl
     }
   }
   allWpPost(filter: { slug: { eq: $slug } },sort: { fields: [date], order: ASC }) {
     edges {
      next{
        title
        slug
        link
      }
      previous{
        title
        slug
        link
      }
       node {
         title
         content
         slug
         link
         date(formatString: "YYYY.MM.DD")
         categories {
          nodes {
            name
            slug
          }
        }
         featuredImage {
           node {
             localFile {
               childImageSharp {
                 fluid {
                   ...GatsbyImageSharpFluid_withWebp_tracedSVG
                 }
               }
             }
           }
         }
       }
     }
   }
 }
`